import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { StoreService, AuthService, ConfirmActionDialogComponent } from 'shared-lib'

@Injectable({
  providedIn: 'root'
})
export class IsOnboardedGuard {
  constructor (
    public auth: AuthService,
    public router: Router,
    private storeService: StoreService,
    private dialog: MatDialog
  ) { }

  canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const org = this.storeService.getCachedOrganization()
    if (org) {
      const hasOnboarded = !!org.org_ready

      if (!hasOnboarded && state.url.includes('dashboard')) {
        const message = 'It appears your account is not fully activated yet. Should this issue continue, kindly reach out to our support team at support@shoutly.com. We sincerely apologize for any inconvenience this may cause.'

        const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
          width: '450px',
          data: { message, type: 'info' }
        })

        dialogRef.afterClosed()
          .subscribe()

        if (!state.url.includes('onboard')) {
          this.router.navigate(['/onboarding'], { queryParamsHandling: 'merge' })
          return false // Prevent navigation to the original route since the user needs to be onboarded first
        }
      }
    }
    return true // Allow the navigation if the organization is onboarded or the URL doesn't contain 'dashboard'
  }
}
