import { Component, OnDestroy, OnInit } from '@angular/core'
import { HubspotService } from 'shared-lib'

@Component({
  selector: 'app-wizard-layout',
  templateUrl: './wizard-layout.component.html',
  styleUrls: ['./wizard-layout.component.scss']
})
export class WizardLayoutComponent implements OnInit, OnDestroy {
  constructor (
    private hubspotService: HubspotService
  ) {}

  ngOnDestroy (): void {
    // this.hubspotService.initHubspotSettings()
  }

  ngOnInit (): void {
    this.hubspotService.removeHubspotWidget()
  }
}
