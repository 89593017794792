import { BrowserModule } from '@angular/platform-browser'
import { LOCALE_ID, NgModule, ErrorHandler, APP_INITIALIZER, isDevMode } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { JwtModule } from '@auth0/angular-jwt'
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { NotificationsModule } from './notifications/notifications.module'
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour'
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from 'src/environments/environment'
import { CookieService } from 'ngx-cookie-service'
import * as Sentry from '@sentry/angular'
import { Router } from '@angular/router'
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics'
import { LayoutsModule } from './layouts/layouts.module'
import { SharedLibModule, PruningTranslationLoader } from 'shared-lib'
import { AppComponent } from './app.component'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import localeSe from '@angular/common/locales/sv'
import localeEs from '@angular/common/locales/es-419'
import { registerLocaleData } from '@angular/common'

registerLocaleData(localeEs)
registerLocaleData(localeSe)

export function tokenGetter () {
  return localStorage.getItem('jwt_token')
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    SharedLibModule.forRoot(environment),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NotificationsModule,
    GuidedTourModule,
    NgxGoogleAnalyticsModule.forRoot(environment.google.analytics.measurement_id),
    LayoutsModule,
    JwtModule.forRoot(
      {
        config: {
          tokenGetter
        }
      }
    ),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useClass: PruningTranslationLoader,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: 'ENVIRONMENT',
      useValue: environment
    },
    GuidedTourService,
    {
      provide: LOCALE_ID,
      useValue: 'sv-SE'
    },
    {
      provide: 'googleTagManagerId',
      useValue: 'GTM-MPN8S6W'
    },
    {
      provide: Window,
      useValue: window
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    CookieService,
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
