import { inject } from '@angular/core'
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable, catchError, map, of } from 'rxjs'
import { StoreService } from 'shared-lib'

export function checkOrgReadyGuard (): CanActivateFn {
  return (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    const storeService: StoreService = inject(StoreService)
    const router: Router = inject(Router)

    return storeService.organization$
      .pipe(
        map(org => {
          try {
            if (org.form === 'Private' && !org.emp_auth) {
              return router.createUrlTree(['/settings/manual-verification'])
            } else {
              return true
            }
          } catch (error) {
            console.error('Error during redirection', error)
            // Redirect to a generic error page if there's an error during redirection
            return router.createUrlTree(['/auth/logout']) // Adjust as necessary for your application
          }
        }),
        catchError(error => {
          console.error('Error in guard', error)
          // Handle any errors caught during the observable stream
          try {
            return of(router.createUrlTree(['/auth/logout'])) // Redirect to an error page
          } catch (redirectError) {
            console.error('Error during error redirection', redirectError)
            // If redirection fails, return false to prevent navigation
            return of(false)
          }
        })
      )
  }
}
