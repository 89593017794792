import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HorizontalUserCabinetComponent } from './components/horizontal-user-cabinet/horizontal-user-cabinet.component'
import { LayoutComponent } from './components/layout/layout.component'
import { TranslateModule } from '@ngx-translate/core'
import { NotificationsModule } from '../notifications/notifications.module'
import { RouterModule } from '@angular/router'
import { WizardLayoutComponent } from './components/wizard-layout/wizard-layout.component'
import { SidebarComponent } from './components/sidebar/sidebar.component'
import { LayoutTitleComponent } from './components/layout-title/layout-title.component'
import { SharedLibModule } from 'shared-lib'
import { HamburgerButtonComponent } from '../shared/components/hamburger-button/hamburger-button.component'
import { OrgSwitcherComponent } from '../shared/components/org-switcher/org-switcher.component'
import { SidebarMenuItemsComponent } from '../shared/components/sidebar-menu-items/sidebar-menu-items.component'

@NgModule({
  declarations: [
    HorizontalUserCabinetComponent,
    LayoutComponent,
    WizardLayoutComponent,
    SidebarComponent,
    LayoutTitleComponent,
    HamburgerButtonComponent,
    OrgSwitcherComponent,
    SidebarMenuItemsComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    NotificationsModule,
    SharedLibModule,
    TranslateModule.forChild()
  ]
})
export class LayoutsModule { }
